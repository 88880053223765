import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./BlogDetails.css";
import { global_pointer } from "../../global_config";
import { useParams } from "react-router-dom";

const BlogDetails = () => {
  const {id} = useParams();
  const [blog, setBlog] = useState(null)

  const formatDate = (date) => {
    let d = new Date(date);
    return d?.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });
  }

  useEffect(()=>{
    fetch(global_pointer.api+'blogs/'+id)
    .then(y=>y.json())
    .then(response=>{
      setBlog(response);
    })
  },[])

  return (
    <React.Fragment>
      <div style={{ background: "#f05" }}>
        <div className="container">
          <Header />
        </div>
      </div>
      <section className="details-container blogs-container">
        <div className="container">
          <section className="blog-content">
            <div className="content-card d-flex flex-column rg" id="article-content">
              <div className="big-img-wraper">
                <img id="article-image" src={global_pointer.ip+blog?.imagepath} width="100%" className="blogs-big-img w-full" alt={blog?.meta_keyword} />
              </div>
              <h1 id="article-title" className="card-heading">{blog?.title}</h1>
              <div className="sub-heading-wraper big-card-wraper d-flex justify-content-between">
                <span className="big-card-date">By: <b> {blog?.author} </b> ·{formatDate(blog?.createdAt)}</span>
                {/* <div className="big-card-bottom d-flex cg">
                  <p className="blogs-share-sm">Share to:</p>
                  <div className="d-flex social-wraper ps-3">
                    <div className="cursor-pointer">
                      <img className="" src="/assets/blogs-fb.svg" /></div>
                    <div className="cursor-pointer">
                      <img className="" src="/assets/blogs-twitter.svg" />
                    </div>
                    <div className="cursor-pointer">
                      <img className="" src="/assets/blogs-printrest.svg" />
                    </div>
                    <div className="cursor-pointer">
                      <img className="" src="/assets/blogs-linkedin.svg" />
                    </div>
                  </div>
                </div> */}
              </div>
              <hr/>
              <div id="article-description" dangerouslySetInnerHTML={{__html: blog?.description}}>

              </div>
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default BlogDetails;
