import React from "react";
import "./BlogCard.css";
import { global_pointer } from "../../global_config";

const BlogCard = ({blog}) => {
  return (
    <React.Fragment>
      <div className="col-md-6 col-lg-4">
        <div href={'/blogs/'+blog?._id} className="blog-card text-decoration-none">
          <img
            src={global_pointer.ip+blog?.imagepath}
            loading="lazy"
          />
          <div className="blog-card-body">
            <h4 className="blog-heading">
              {blog?.title}
            </h4>
            <div className="blog-desc truncate-2" dangerouslySetInnerHTML={{__html: blog?.description}}>
            </div>
            <button onClick={()=>window.open('/blogs/'+(blog?.meta_keyword?.toLowerCase()?.replace(/ /g,'-'))+'/'+blog?._id)} className="read-more-btn">Read Post</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlogCard;
