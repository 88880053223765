import React, { useEffect, useState } from "react";
import BlogCard from "../BlogCard/BlogCard";
import Pagination from "../pagination/pagination";
import { global_pointer } from "../../global_config";

const BlogSection = ({category}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // pagination
  const [numPosts, setNumPosts] = useState(6);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 767) {
        setNumPosts(2);
      } else if (width >= 768 && width <= 1023) {
        setNumPosts(4);
      } else {
        setNumPosts(6);
      }
    };

    handleResize(); // Initial check on component mount

    window.addEventListener("resize", handleResize); // Listen to window resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on component unmount
    };
  }, []);

  useEffect(()=>{
    setLoading(true);
    fetch(global_pointer.api+"blogs")
    .then(y=>y.json())
    .then(response=>{
      response = response?.filter(item=>{
        let div = document.createElement('div');
        div.innerHTML = item.description;
        let desc = div.querySelector('p.card-mid-p');
        item.description = desc.innerText;
        return item.catagory==category || category=="all";
      })
      setData(response)
      setLoading(false);
    })
  },[])

  const [currentPage, setCurrentPage] = useState(1);
  //   const ITEMS_PER_PAGE = 6;
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  // Assuming you have a list of items
  const totalItems = data.length; // Total number of items
  const totalPages = Math.ceil(totalItems / numPosts);
  const startIndex = (currentPage - 1) * numPosts;
  const endIndex = Math.min(startIndex + numPosts, totalItems);
  const itemsToShow = [...data].slice(startIndex, endIndex);

  return (
    <>
      {
        itemsToShow?.length && !loading ?
        <React.Fragment>
          <div className="row justify-fluid g-4">
            {itemsToShow?.map((item, index) => (
              <BlogCard key={index} blog={item} />
            ))}
          </div>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={onPageChange}
          />
        </React.Fragment>
        :
          !loading?
          <div className="py-5 text-white text-center">No Data Found</div>
        :
        <>
          <div className="row justify-fluid g-4">
            {[1,2,3,4,5,6].map(x=>{
              return (<div key={x} className="placeholder-glow col-md-6 col-lg-4">
                <div className="col-12 placeholder bg-secondary" style={{height: '500px'}}>
                  <div className="col-12 placeholder bg-white" style={{height: '280px'}}></div>
                  <div className="col-12 placeholder bg-secondary px-2" style={{height: '220px'}}>
                    <div className="col-md-12 placeholder bg-white py-2 mt-2" style={{height: '30px'}}></div>
                    <div className="col-md-12 placeholder bg-white py-2 mt-2" style={{height: '30px'}}></div>
                    <div className="col-md-12 placeholder bg-white py-2 mt-4" style={{height: '20px'}}></div>
                    <div className="col-md-12 placeholder bg-white py-2 mt-2" style={{height: '20px'}}></div>
                    <div className="d-flex justify-content-center mt-4">
                      <div className="col-md-6 placeholder bg-white py-2 mx-auto" style={{height: '40px'}}></div>
                    </div>
                  </div>
                </div>
              </div>)
            })}
          </div>
        </>
      }

    </>
  );
};

export default BlogSection;
