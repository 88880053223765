import React from "react";
import "./Blog.css";
import Header from "../../components/header/Header";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import BlogSection from "../../components/BlogSection/BlogSection";
import Footer from "../../components/footer/Footer";

const Blog = () => {
  return (
    <React.Fragment>
      {/* page intro */}
      <section className="blog-hero">
        <div className="container h-100">
          <div className="d-flex flex-column justify-content-between align-items-center h-100">
            <Header />
            <div className="para-width text-center">
              <h2 className="page-heading">Widad's Blog</h2>
              <p className="page-para">
                Dive into our blog for the latest in workout routines, nutrition
                advice, and wellness strategies to elevate your fitness
                experience.
              </p>
            </div>
            <div></div>
          </div>
        </div>
      </section>
      {/* Motivational quotes */}
      <section className="quotation-section">
        <div className="container">
          <Splide
            className="quotation-carousel"
            options={{
              type: "loop",
              perPage: 1,
              perMove: 1,
              pagination: false,
              arrows: true,
              autoplay: true,
              interval: 3000,
            }}
          >
            <SplideSlide>
              <div className="item">
                <h2>
                  Fitness is not about being better than someone else; it's
                  about being better than you used to be.
                </h2>
                <cite>- Widad Naji</cite>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <h2>
                  Fall in love with taking care of yourself – mind, body, and
                  spirit.
                </h2>
                <cite>- Widad Naji</cite>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <h2>Don't stop when you're tired; stop when you're done.</h2>
                <cite>- Widad Naji</cite>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="item">
                <h2>
                  Fitness is not about being better than someone else; it's
                  about being better than you used to be.
                </h2>
                <cite>- Widad Naji</cite>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </section>
      {/* blog section */}
      <section className="blogs-section">
        <div className="container">
          {/* navs and tabs */}
          <ul class="nav nav-tabs blog-nav" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="allPosts-tab" data-bs-toggle="tab" data-bs-target="#allPosts-tab-pane" type="button" role="tab" aria-controls="allPosts-tab-pane" aria-selected="true">All Posts</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="workouts-tab" data-bs-toggle="tab" data-bs-target="#workouts-tab-pane" type="button" role="tab" aria-controls="workouts-tab-pane" aria-selected="false" tabindex="-1">Workouts</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="recipes-tab" data-bs-toggle="tab" data-bs-target="#recipes-tab-pane" type="button" role="tab" aria-controls="recipes-tab-pane" aria-selected="false" tabindex="-1">Recipes</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="health-tab" data-bs-toggle="tab" data-bs-target="#health-tab-pane" type="button" role="tab" aria-controls="health-tab-pane" aria-selected="false" tabindex="-1">Health</button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            {/* all posts  */}
            <div
              className="tab-pane fade show active"
              id="allPosts-tab-pane"
              role="tabpanel"
              aria-labelledby="allPosts-tab"
              tabIndex="0"
            >
              <BlogSection category="all" />
            </div>
            {/* workouts  */}
            <div
              className="tab-pane fade"
              id="workouts-tab-pane"
              role="tabpanel"
              aria-labelledby="workouts-tab"
              tabIndex="0"
            >
              <BlogSection category="workout" />
            </div>
            {/* Recipes  */}
            <div
              className="tab-pane fade"
              id="recipes-tab-pane"
              role="tabpanel"
              aria-labelledby="recipes-tab"
              tabIndex="0"
            >
              <BlogSection category="recipe" />
            </div>
            {/* Health  */}
            <div
              className="tab-pane fade"
              id="health-tab-pane"
              role="tabpanel"
              aria-labelledby="health-tab"
              tabIndex="0"
            >
              <BlogSection category="health" />
            </div>
          </div>
          {/* <button className="all-posts-btn">View All Posts</button> */}
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Blog;
