import React, { useState } from "react";
import "./Contact.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const Contact = () => {
  const [showToast, setShowToast] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const formData = {
      fname: event.target[0].value,
      lname: event.target[1].value,
      phone: event.target[2].value,
      email: event.target[3].value,
      msg: event.target[4].value,
    };

    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 5000);

    console.log(formData);
  };

  return (
    <React.Fragment>
      {/* page intro */}
      <section className="blog-hero">
        <div className="container h-100">
          <div className="d-flex flex-column justify-content-between align-items-center h-100">
            <Header />
            <div className="para-width text-center">
              <h2 className="page-heading">CONTACT</h2>
              <a href="mailto:support@widadnaji.com" className="page-para">
                <strong>Hello. I’ve been expecting you.</strong> <br />
                Fill in the form, or, if you prefer, send me an{" "}
                <span className="text-decoratrion-underline">email</span>.
              </a>
            </div>
            <div></div>
          </div>
        </div>
      </section>
      <section className="contact-form-wrapper">
        <div className="row justify-content-center mx-0">
          <div className="col-lg-6">
            <div className="form-card">
              <form className="row g-4" onSubmit={handleFormSubmit}>
                <div className="col-md-6">
                  <label htmlFor="fname" className="widi-form-label">
                    FIRST NAME
                  </label>
                  <input
                    type="text"
                    className="form-control widi-form"
                    placeholder="Enter First Name"
                    id="fname"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="lname" className="widi-form-label">
                    LAST NAME
                  </label>
                  <input
                    type="text"
                    className="form-control widi-form"
                    placeholder="Enter Last Name"
                    id="lname"
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="phone" className="widi-form-label">
                    PHONE
                  </label>
                  <input
                    type="text"
                    className="form-control widi-form"
                    placeholder="Enter Phone Number"
                    id="phone"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="email" className="widi-form-label">
                    EMAIL
                  </label>
                  <input
                    type="email"
                    className="form-control widi-form"
                    placeholder="Enter Email Adress"
                    id="email"
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="msg" className="widi-form-label">
                    HOW CAN I HELP YOU?
                  </label>
                  <textarea
                    rows="4"
                    className="form-control widi-form"
                    id="msg"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <div className="col-md-12">
                  <button type="submit" className="submit-btn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {showToast && (
        <div className="toast-container position-fixed bottom-0 end-0 p-3">
          <div
            id="liveToast"
            className="toast d-block"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header">
              <img
                src="/favicon.ico"
                className="rounded me-2"
                width="25"
                alt="..."
              />
              <strong className="me-auto">Widad Naji</strong>
              <small>Just Now</small>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
                onClick={() => setShowToast(false)}
              ></button>
            </div>
            <div className="toast-body">
              Hello, we just got your message. We will get back to you shortly.
            </div>
          </div>
        </div>
      )}
      <Footer />
    </React.Fragment>
  );
};

export default Contact;
